import Marquee3k from "marquee3000";
import { debounce, throttle } from "lodash";
import { getScrollTop } from "../util/lib";

const MessageCentre = (el) => {
  const doMarquee = el.dataset.componentSettings === "hud";
  const messageSections = [
    ...document.querySelectorAll("[data-message-centre]"),
  ];
  let messageInOuts = [];
  let currentMessage = "";
  let currentScrollMessage = null;
  let currentOverrideMessage = null;
  const isDeviceMotionDevice =
    typeof DeviceMotionEvent !== "undefined" &&
    typeof DeviceMotionEvent.requestPermission === "function";
  let scrollDirection = 1;
  let prevSt = getScrollTop();

  const getMessageText = (message) => {
    if (message === "move-mouse") {
      if (window.hasDeviceMotion) {
        return "Tilt your device";
      }
      if (isDeviceMotionDevice) {
        return "Activate tilt mode";
      }
      return "Move your mouse";
    }

    if (message === "explore-universe") {
      return "Explore remote quadrants";
    }

    if (message === "tunnel") {
      return "Traverse infinity";
    }

    if (message === "hold-on") {
      return "Buckle in to ensure pilot safety";
    }

    if (message === "scroll-now") {
      return "Scroll to start your journey";
    }

    if (message === "alternates") {
      return "Galaxy of alternates";
    }

    if (message === "charset") {
      return "Zoom zoom zoom";
    }

    return "Stay focused";
  };

  const updateMessage = throttle(
    (newMessage) => {
      if (newMessage === currentMessage) return;
      currentMessage = newMessage;

      const previousMessageEl = el.querySelector(
        "div:not([data-outgoing='true'])"
      );

      const translate = 100 * scrollDirection;

      const innerEl = document.createElement("div");
      const innerSpan = document.createElement("span");
      innerSpan.innerHTML = `&nbsp;&nbsp;${getMessageText(
        currentMessage
      )}&nbsp;&nbsp;`;
      innerEl.appendChild(innerSpan);
      innerEl.style.transform = `translateY(${translate}%)`;
      if (doMarquee) {
        innerEl.classList.add("marquee3k");
      }
      el.appendChild(innerEl);

      requestAnimationFrame(() => {
        if (doMarquee) {
          Marquee3k.init();
        }
        requestAnimationFrame(() => {
          innerEl.style.transform = "translateY(0%)";
        });
      });
      if (previousMessageEl) {
        previousMessageEl.dataset.outgoing = true;
        previousMessageEl.classList.remove("marquee3k");
        previousMessageEl.style.transform = "translateY(0%)";
        setTimeout(() => {
          previousMessageEl.parentElement.removeChild(previousMessageEl);
        }, 160);
        requestAnimationFrame(() => {
          previousMessageEl.style.transform = `translateY(${translate * -1}%)`;
        });
      }
    },
    400,
    { leading: true, trailing: true }
  );

  const cacheInOut = throttle(() => {
    const st = getScrollTop();

    messageInOuts = [];
    messageSections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      messageInOuts.push({
        in: rect.top + st - window.innerHeight / 4,
        out: rect.bottom + st - window.innerHeight / 4,
        message: section.dataset.messageCentre,
      });
    });
  }, 222);

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      document.body.addEventListener("scroll", onScroll, { passive: true });
      requestAnimationFrame(() => {
        onScroll();
        onScroll.flush();
      });
    } else {
      document.body.removeEventListener("scroll", onScroll);
    }
  };

  const onScroll = throttle(() => {
    if (currentOverrideMessage) return;

    const st = getScrollTop();
    scrollDirection = st > prevSt ? 1 : -1;
    prevSt = st;

    for (let i = messageSections.length - 1; i >= 0; i--) {
      const { in: _in, out, message } = messageInOuts[i];
      if (st > _in && st < out) {
        currentScrollMessage = message;
        updateMessage(message);
        return;
      }
    }
    currentScrollMessage = "default";
    updateMessage("default");
  }, 222);

  const onResize = debounce(() => {
    width = window.innerWidth;
    height = window.innerHeight;
  }, 111);

  const onOverrideMessage = (e) => {
    if (!e.detail) {
      currentOverrideMessage = null;
      updateMessage(currentScrollMessage);
      return;
    }
    currentOverrideMessage = e.detail;
    updateMessage(currentOverrideMessage);
  };

  window.addEventListener("message-centre-override", onOverrideMessage);

  const resizeObserver = new ResizeObserver(cacheInOut);
  resizeObserver.observe(document.body);

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);
  window.addEventListener("resize", onResize);

  cacheInOut();
  cacheInOut.flush();
};

export default MessageCentre;
