const HUDTapeCounter = (el) => {
  const duration = parseInt(el.dataset.duration);
  const countEl = el.querySelector(
    "[data-component='hud__tape-counter-count']"
  );

  let currentCount = parseInt(countEl.innerHTML);

  const inc = () => {
    currentCount++;
    countEl.innerHTML = currentCount;
    setTimeout(inc, duration);
  };

  setTimeout(inc, duration / 2); // duration / 2 because the counter position starts half way through a loop
};

export default HUDTapeCounter;
