{
  "defMessage": "Choose your own adventure",
  "def": [
    {
      "text": "MAY THE FORCE BE WITH YOU",
      "type": "default",
      "wght": 700,
      "slnt": -45,
      "features": {
        "flatAlts": false,
        "curvedNumbers": true,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "Live Long & Prosper",
      "type": "default",
      "wght": 100,
      "slnt": 0,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "Flash Gordon",
      "type": "default",
      "wght": 300,
      "slnt": 45,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "PAST FUTURES BEHIND EXTRAPOLATED MEMORIES",
      "type": "default",
      "wght": 900,
      "slnt": 45,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "TIME IS THE FIRE IN WHICH WE BURN",
      "type": "default",
      "wght": 400,
      "slnt": 8,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "SATURN NAVCOM REPORTS READY",
      "type": "default",
      "wght": 500,
      "slnt": 0,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "WHERE NO TYPE HAS BEEN BEFORE",
      "type": "default",
      "wght": 700,
      "slnt": 0,
      "features": {
        "flatAlts": false,
        "curvedNumbers": false,
        "singleStoryAlts": false
      },
      "isDefaultAnimated": false
    },
    {
      "text": "Highly Illogical",
      "type": "default",
      "wght": 400,
      "slnt": 0,
      "features": {
        "flatAlts": true,
        "curvedNumbers": false,
        "singleStoryAlts": true
      },
      "isDefaultAnimated": false
    }
  ],
  "tv": {
    "STA": {
      "messageCenter": "In a galaxy far, far away"
    },
    "DOM": {
      "messageCenter": "Let the chips fall where they may"
    },
    "BOU": {
      "messageCenter": "Scratch that itch"
    },
    "IMP": {
      "messageCenter": "Force radiating inward"
    },
    "ENE": {
      "messageCenter": "Stored potentials"
    },
    "R_0": {
      "messageCenter": "They made me add this"
    }
  }
}
