import { debounce } from "lodash";
import { getScrollTop, lerp } from "../../util/lib.js";

const HUDDistance = (el) => {
  const distanceEl = el.querySelector("[data-component='hud__distance-text']");

  let raf;
  let isPolling = false;

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    distanceEl.innerHTML = `${Math.floor(st)}px`;

    if (loop) {
      raf = requestAnimationFrame(pollScroll);
    }
  };

  const stopPollScroll = debounce(() => {
    isPolling = false;
    cancelAnimationFrame(raf);
  }, 1111);

  const onScroll = () => {
    if (!isPolling) {
      isPolling = true;
      then = performance.now();
      raf = requestAnimationFrame(pollScroll);
    }
    stopPollScroll();
  };

  document.body.addEventListener("scroll", onScroll, { passive: true });
  pollScroll(false);
};

export default HUDDistance;
