import useOrientationOrMousePosition from "../../util/useOrientationOrMousePosition";
import presetConfig from "../../../config/typetester-presets.json";
import TypeTesterSample from "./TypeTesterSample";
import { convertToRange, lerp } from "../../util/lib";
import { clamp } from "lodash";
import TypeTesterMessageCenter from "./TypeTesterMessageCenter";

const TRANSITION_DURATION = 300;

const TypeTester = (el) => {
  let currentFontFeatureSettings = [];
  let powerSteeringEnabled = false;
  let angleIndicatorClickOrigin = 0;
  let angleIndicatorClickValue = 0;
  let activePreset = null;
  let isAutopilot = false;
  let autopilotRaf = null;
  let isVisible = false;
  let isTransitioning = false;

  const toggleEls = document.querySelectorAll("[data-component='tt__toggle']");
  toggleEls.forEach((toggleEl) =>
    toggleEl.addEventListener("click", () => {
      isVisible = !isVisible;
      document.body.classList.toggle("typetester-overlay-active");

      if (isVisible) {
        presetDefBtn.click();
      } else {
        hideSampleOrTvElement(sampleEl);
        tvPresetEls.forEach((el) => hideSampleOrTvElement(el));
        togglePowerSteering(false);
        toggleAutopilot(false);
      }

      const toggleEvent = new CustomEvent("tt-toggle", {
        detail: isVisible,
      });
      window.dispatchEvent(toggleEvent);
    })
  );

  window.addEventListener("hud-toggle", ({ detail }) => {
    if (detail) {
      isVisible = false;
      document.body.classList.remove("typetester-overlay-active");
      hideSampleOrTvElement(sampleEl);
      tvPresetEls.forEach((el) => hideSampleOrTvElement(el));
      togglePowerSteering(false);
      toggleAutopilot(false);
    }
  });

  const autopilotBtn = el.querySelector("[data-component='tt__autopilot']");
  const angleIndicator = el.querySelector(
    "[data-component='tt__angle-indicator']"
  );
  const wghtDisplay = el.querySelector("[data-component='tt__wght-display']");
  const slntDisplay = el.querySelector("[data-component='tt__slnt-display']");
  const wghtNotches = el.querySelector("[data-component='tt__wght-notches']");
  let weightNotchChildren = [];
  const wghtSliders = [
    ...el.querySelectorAll("[data-component='tt__wght-slider']"),
  ];
  const slntSliders = [
    ...el.querySelectorAll("[data-component='tt__slnt-slider']"),
  ];
  const wghtBtns = [...el.querySelectorAll("[data-component='tt__wght-btn']")];
  const slntBtns = [...el.querySelectorAll("[data-component='tt__slnt-btn']")];
  const featureBtns = [
    ...el.querySelectorAll("[data-component='tt__features-btn']"),
  ];
  const powerSteeringBtns = [
    ...el.querySelectorAll("[data-component='tt__powersteering']"),
  ];
  const presetBtns = [
    ...el.querySelectorAll("[data-component='tt__preset-btn']"),
  ];
  const presetDefBtn = el.querySelector(
    "[data-component='tt__preset-btn'][data-preset='DEF']"
  );
  const controlAreas = [
    ...el.querySelectorAll("[data-component='tt__control-area']"),
  ];

  const sampleEl = el.querySelector("[data-component='tt__sample']");
  // const tvEl = el.querySelector("[data-component='tt__tv']");
  const tvPresetEls = [
    ...el.querySelectorAll("[data-component='tt__tv-preset']"),
  ];
  const tvPresetAreaEl = el.querySelector(
    "[data-component='tt__presets-wrapper']"
  );

  const messageEl = el.querySelector("[data-component='tt__message-center']");

  const { refresh: refreshSize } = TypeTesterSample(sampleEl);
  const { updateMessage: updateMessageCenterMessage } =
    TypeTesterMessageCenter(messageEl);

  const setSlnt = (value) => {
    el.style.setProperty("--user-slnt", value);
    slntSliders.forEach((el) => (el.value = value));
    const slntValue = `${Math.round(value) >= 0 ? "+" : ""}${Math.round(
      value
    )}`;
    slntDisplay.innerHTML = slntValue;
    angleIndicator.style.transform = `rotate(${value}deg)`;

    slntBtns.forEach((el) => {
      el.classList.remove("typetester-desktop-angle-active-angle");
    });
    const activeSlntBtns = [
      ...document.querySelectorAll(
        `[data-component='tt__slnt-btn'][data-value='${Math.round(value)}']`
      ),
    ];
    activeSlntBtns.forEach((el) => {
      el.classList.add("typetester-desktop-angle-active-angle");
    });
  };

  const setWght = (value) => {
    el.style.setProperty("--user-wght", value);
    wghtSliders.forEach((el) => (el.value = value));
    const wghtValue = `${Math.round(value)}`;
    wghtDisplay.innerHTML = wghtValue;
    wghtBtns.forEach((el) => {
      el.classList.remove("typetester-desktop-weight-active-weight");
    });
    const activeWeightBtns = [
      ...document.querySelectorAll(
        `[data-component='tt__wght-btn'][data-value='${Math.round(value)}']`
      ),
    ];
    activeWeightBtns.forEach((el) => {
      el.classList.add("typetester-desktop-weight-active-weight");
    });

    const notchLimit = Math.round(
      convertToRange(value, [100, 900], [0, weightNotchChildren.length - 1])
    );

    weightNotchChildren.forEach((el, i) => {
      if (i < notchLimit) {
        el.classList.remove("bg-black");
        el.classList.remove("bg-green");
        el.classList.add("bg-purple");
      } else if (i === notchLimit) {
        el.classList.remove("bg-black");
        el.classList.add("bg-green");
        el.classList.remove("bg-purple");
      } else {
        el.classList.add("bg-black");
        el.classList.remove("bg-green");
        el.classList.remove("bg-purple");
      }
    });
  };

  const onWghtUpdate = (e) => {
    setWght(e.target.value);
    requestAnimationFrame(refreshSize);
  };

  const onSlntUpdate = (e) => {
    setSlnt(e.target.value);
    requestAnimationFrame(refreshSize);
  };

  const onWghtClick = (e) => {
    setWght(parseInt(e.target.dataset.value));
    requestAnimationFrame(refreshSize);
    if (powerSteeringEnabled) {
      togglePowerSteering();
    }
  };

  const onSlntClick = (e) => {
    setSlnt(parseInt(e.target.dataset.value));
    requestAnimationFrame(refreshSize);
    if (powerSteeringEnabled) {
      togglePowerSteering();
    }
  };

  const onMouseMove = ([x, y], [pcX, pcY]) => {
    const slnt = lerp(-45, 45, pcX);
    const wght = lerp(100, 900, pcY);

    setSlnt(slnt);
    setWght(wght);
    requestAnimationFrame(refreshSize);
  };
  const onOrientation = ([x, y], [pcX, pcY]) => {
    const slnt = lerp(-45, 45, pcX);
    const wght = lerp(100, 900, pcY);

    setSlnt(slnt);
    setWght(wght);
    requestAnimationFrame(refreshSize);
  };

  const {
    enable: enableOrientationOrMouse,
    disable: disableOrientationOrMouse,
  } = useOrientationOrMousePosition(onMouseMove, onOrientation);

  const togglePowerSteering = (isEnabled) => {
    powerSteeringEnabled =
      typeof isEnabled === "boolean" ? isEnabled : !powerSteeringEnabled;

    if (powerSteeringEnabled) {
      toggleAutopilot(false);
      powerSteeringBtns.forEach((button) =>
        button.classList.add("typetester-powersteering-active")
      );
      el.classList.add("powersteering-active");
      enableOrientationOrMouse();
    } else {
      powerSteeringBtns.forEach((button) =>
        button.classList.remove("typetester-powersteering-active")
      );
      el.classList.remove("powersteering-active");
      disableOrientationOrMouse();
    }
  };

  const onMouseMoveAngleIndicator = ({ clientX }) => {
    const delta = clientX - angleIndicatorClickOrigin;
    const newValue = clamp(angleIndicatorClickValue + delta * 0.7, -45, 45);
    setSlnt(newValue);
  };

  const onMouseUpAngleIndicator = () => {
    window.removeEventListener("mousemove", onMouseMoveAngleIndicator);
    window.removeEventListener(
      "onMouseUpAngleIndicator",
      onMouseUpAngleIndicator
    );
  };

  const onMouseDownAngleIndicator = ({ clientX }) => {
    angleIndicatorClickOrigin = clientX;
    angleIndicatorClickValue = parseInt(slntDisplay.innerHTML);
    window.addEventListener("mousemove", onMouseMoveAngleIndicator);
    window.addEventListener("mouseup", onMouseUpAngleIndicator);
    if (powerSteeringEnabled) {
      togglePowerSteering();
    }
  };

  const onClickFeatureBtn = (e) => {
    if (e.target.dataset.active === "true") {
      e.target.dataset.active = "false";
      e.target.firstElementChild.classList.remove(
        "typetester-features-toggle-indicator-on"
      );
      const targetSettings = e.target.dataset.settings.split(",");
      currentFontFeatureSettings = currentFontFeatureSettings.filter(
        (setting) => {
          return targetSettings.indexOf(setting) === -1;
        }
      );
    } else {
      e.target.dataset.active = "true";
      e.target.firstElementChild.classList.add(
        "typetester-features-toggle-indicator-on"
      );

      const targetSettings = e.target.dataset.settings.split(",");
      currentFontFeatureSettings = [
        ...currentFontFeatureSettings,
        ...targetSettings,
      ];
    }

    if (!currentFontFeatureSettings.length) {
      el.style.setProperty("--user-font-feature-settings", "");
    } else {
      el.style.setProperty(
        "--user-font-feature-settings",
        currentFontFeatureSettings.reduce((acc, value) => {
          return `${acc}, "${value}" 1`;
        }, "'kern' 1")
      );
    }
  };

  const onMouseEnterPresetButton = (e) => {
    if (e.target.dataset.preset === "DEF") {
      updateMessageCenterMessage(presetConfig.defMessage);
    } else {
      const { messageCenter } = presetConfig.tv[e.target.dataset.preset];
      updateMessageCenterMessage(messageCenter);
    }
  };

  const onMouseLeavePresetButton = () => {
    if (activePreset.dataset.preset === "DEF") {
      updateMessageCenterMessage(presetConfig.defMessage);
    } else {
      const { messageCenter } = presetConfig.tv[activePreset.dataset.preset];
      updateMessageCenterMessage(messageCenter);
    }
  };

  const showSampleOrTvElement = (el) => {
    if (!el.classList.contains("hidden")) {
      // it's already visible
      return;
    }

    el.classList.remove("exit-transition");
    el.classList.remove("exit-transition-active");

    el.classList.add("enter-transition");
    el.offsetTop; // force reflow;
    el.classList.remove("hidden");
    el.offsetTop; // force reflow;
    el.classList.add("enter-transition-active");
  };

  const hideSampleOrTvElement = (el) => {
    if (el.classList.contains("hidden")) {
      // it's already hidden
      return;
    }

    el.classList.remove("enter-transition");
    el.classList.remove("enter-transition-active");

    el.classList.add("exit-transition");
    el.offsetTop; // force reflow;
    el.classList.add("exit-transition-active");

    setTimeout(() => {
      el.classList.add("hidden");
    }, TRANSITION_DURATION);
  };

  const onClickPresetButton = (e) => {
    if (isTransitioning) return;
    isTransitioning = true;

    setTimeout(() => {
      isTransitioning = false;
    }, TRANSITION_DURATION);
    if (powerSteeringEnabled) {
      togglePowerSteering();
    }

    activePreset = e.currentTarget;

    presetBtns.forEach((el) => {
      if (el.dataset.preset === activePreset.dataset.preset) {
        el.classList.add("typetester-preset-active");
      } else {
        el.classList.remove("typetester-preset-active");
      }
    });
    e.currentTarget.classList.add("typetester-preset-active");

    featureBtns.forEach((el) => {
      el.dataset.active = "false";
      el.firstElementChild.classList.remove(
        "typetester-features-toggle-indicator-on"
      );
    });

    sampleEl.dataset.activePreset = activePreset.dataset.preset;
    requestAnimationFrame(refreshSize);

    if (activePreset.dataset.preset === "DEF") {
      const { features, isDefaultAnimated, slnt, wght, text } =
        presetConfig.def[Math.floor(Math.random() * presetConfig.def.length)];

      updateMessageCenterMessage(presetConfig.defMessage);
      toggleAutopilot(isDefaultAnimated);

      setSlnt(slnt);
      setWght(wght);

      featureBtns.forEach((button) => {
        if (e.target.dataset.active === "true") {
          button.click();
        }
      });

      sampleEl.innerHTML = text;
      if (features.flatAlts) {
        document.querySelector("[data-name='flatAlts']").click();
      }
      if (features.curvedNumbers) {
        document.querySelector("[data-name='curvedNumbers']").click();
      }
      if (features.singleStoryAlts) {
        document.querySelector("[data-name='singleStoryAlts']").click();
      }

      controlAreas.forEach((el) =>
        el.classList.remove("typetester-control-area-deactivated")
      );
      showSampleOrTvElement(sampleEl);
      tvPresetEls.forEach((el) => hideSampleOrTvElement(el));
    } else {
      const { messageCenter } = presetConfig.tv[activePreset.dataset.preset];
      const presetEl = el.querySelector(
        `[data-component='tt__tv-preset'][data-name='${activePreset.dataset.preset}']`
      );
      controlAreas.forEach((el) =>
        el.classList.add("typetester-control-area-deactivated")
      );
      tvPresetEls.forEach((el) => {
        if (el !== presetEl) {
          hideSampleOrTvElement(el);
        }
      });
      hideSampleOrTvElement(sampleEl);

      updateMessageCenterMessage(messageCenter);
      toggleAutopilot(true);

      if (presetEl) {
        showSampleOrTvElement(presetEl);
      }
    }
  };

  const setWghtNotches = () => {
    weightNotchChildren = [];
    wghtNotches.innerHTML = "";
    const count = wghtNotches.getBoundingClientRect().width / 8;
    for (let i = 0; i < count; i++) {
      const notchEl = document.createElement("div");
      notchEl.className = "w-[2px] h-full bg-purple";
      wghtNotches.appendChild(notchEl);
      weightNotchChildren.push(notchEl);
    }
  };

  const autopilotUpdateIndicators = () => {
    const computedStyle = window.getComputedStyle(sampleEl);
    const fontVariationSettings = computedStyle.getPropertyValue(
      "font-variation-settings"
    );

    const slntMatches = /"slnt" (-?\d+)/g.exec(fontVariationSettings);
    const slnt = parseFloat(slntMatches[1]);

    const wghtMatches = /"wght" (\d+)/g.exec(fontVariationSettings);
    const wght = parseFloat(wghtMatches[1]);

    setSlnt(slnt);
    setWght(wght);
    autopilotRaf = requestAnimationFrame(autopilotUpdateIndicators);
  };

  const toggleAutopilot = (onOff) => {
    if (typeof onOff === "boolean") {
      isAutopilot = onOff;
    } else {
      isAutopilot = !isAutopilot;
    }
    cancelAnimationFrame(autopilotRaf);

    if (isAutopilot) {
      autopilotRaf = requestAnimationFrame(autopilotUpdateIndicators);
      el.classList.add("autopilot-active");
    } else {
      el.classList.remove("autopilot-active");
    }
  };

  angleIndicator.addEventListener("mousedown", onMouseDownAngleIndicator);
  wghtSliders.forEach((el) => el.addEventListener("input", onWghtUpdate));
  slntSliders.forEach((el) => el.addEventListener("input", onSlntUpdate));
  wghtBtns.forEach((el) => el.addEventListener("click", onWghtClick));
  slntBtns.forEach((el) => el.addEventListener("click", onSlntClick));
  powerSteeringBtns.forEach((el) =>
    el.addEventListener("click", togglePowerSteering)
  );
  featureBtns.forEach((el) => el.addEventListener("click", onClickFeatureBtn));
  presetBtns.forEach((el) => {
    el.addEventListener("mouseenter", onMouseEnterPresetButton);
    el.addEventListener("click", onClickPresetButton);
  });
  tvPresetAreaEl.addEventListener("mouseleave", onMouseLeavePresetButton);
  autopilotBtn.addEventListener("click", toggleAutopilot);

  const onInViewChange = (entries) => {
    if (!entries[0].isIntersecting) {
      if (powerSteeringEnabled) {
        togglePowerSteering();
        toggleAutopilot(false);
      }
    } else {
      refreshSize();
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  setWghtNotches();
  inViewObserver.observe(sampleEl);
};

export default TypeTester;
