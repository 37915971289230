import { throttle } from "lodash";
import Marquee3k from "marquee3000";

const TypeTesterMessageCenter = (el) => {
  let currentMessage = null;
  let isVisible = true;

  const updateMessage = throttle(
    (newMessage) => {
      if (newMessage === currentMessage) return;
      currentMessage = newMessage;

      const outgoingId = `outgoing_${Date.now()}`;
      const previousMessageEl = el.querySelector(
        "div:not([data-outgoing='true'])"
      );

      const translate = 100;

      const innerEl = document.createElement("div");
      const innerSpan = document.createElement("span");
      innerSpan.innerHTML = `&nbsp;&nbsp;${currentMessage}&nbsp;&nbsp;`;
      innerEl.appendChild(innerSpan);
      innerEl.style.transform = `translateY(${translate}%)`;
      innerEl.classList.add("tt__marquee3k");

      el.appendChild(innerEl);

      if (previousMessageEl) {
        previousMessageEl.id = outgoingId;
        setTimeout(() => {
          requestAnimationFrame(() => {
            const outgoingEl = el.querySelector(`#${outgoingId}`);
            outgoingEl.parentElement.removeChild(outgoingEl);
          });
        }, 200);

        const onTransitionEnd = () => {
          previousMessageEl.removeEventListener(
            "transitionend",
            onTransitionEnd
          );
        };
        previousMessageEl.dataset.outgoing = true;
        previousMessageEl.classList.remove("tt__marquee3k");
        previousMessageEl.style.transform = "translateY(0%)";
        previousMessageEl.addEventListener("transitionend", onTransitionEnd);
        requestAnimationFrame(() => {
          previousMessageEl.style.transform = `translateY(${translate * -1}%)`;
        });
      }

      requestAnimationFrame(() => {
        Marquee3k.init({ selector: "tt__marquee3k" });
        requestAnimationFrame(() => {
          innerEl.classList.remove("tt__marquee3k");
          innerEl.style.transform = "translateY(0%)";
        });
      });
    },
    600,
    { leading: true, trailing: true }
  );

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      isVisible = true;
    } else {
      isVisible = false;
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);

  return { updateMessage };
};

export default TypeTesterMessageCenter;
