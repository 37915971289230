import { getScrollTop } from "../util/lib";
import { clamp } from "lodash";

const OFFSET_PER_LEVEL = window.innerWidth > 1024 ? 450 : 250; // how many pixels is each element in the tunnel offset
const SCROLL_PER_LEVEL = OFFSET_PER_LEVEL / 3; // [x]vh — how far the user must scroll down the page per element

const SectionSeperator = (el) => {
  const debugEl = el.querySelector("[data-component='ss__debug']");
  let raf;
  let min, max;
  const planeEls = [
    ...el.querySelectorAll("[data-component='ss__tunnel-plane']"),
  ];
  const gridZEls = [...el.querySelectorAll("[data-component='ss__grid--z']")];
  const stepCount = planeEls.length;
  const stepsProgress = new Array(stepCount).fill(0);
  const tunnelLength = OFFSET_PER_LEVEL * stepCount;

  el.style.setProperty("--tunnel-length", `${tunnelLength}px`);
  el.style.setProperty("--scroll-height", `${stepCount * SCROLL_PER_LEVEL}vh`);

  // const setGrid = () => {
  //   gridZEls.forEach((el) => {
  //     for (i = 0; i < stepCount * 3; i++) {
  //       const line = document.createElement("div");
  //       line.className = "h-[2px] bg-red block w-full";
  //       el.appendChild(line);
  //     }
  //   });
  // };

  const setStepOffsets = () => {
    let offset = OFFSET_PER_LEVEL * -1;
    for (let i = stepCount - 1; i >= 0; i--) {
      planeEls[i].style.setProperty("--offset", `${offset}px`);
      offset -= OFFSET_PER_LEVEL;
    }
  };

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    const pc = clamp((st - min) / (max - min), 0, 1);
    const currentStep = Math.max(Math.floor(pc * stepCount), 0);
    const currentStepPc = pc * stepCount - currentStep;

    for (let i = 0; i < stepCount; i++) {
      if (i < currentStep) {
        stepsProgress[i] = 1;
      } else if (i === currentStep) {
        stepsProgress[i] = currentStepPc;
      } else if (i > currentStep) {
        stepsProgress[i] = 0;
      }
    }

    el.style.setProperty(
      "--tunnel-translate",
      `translateZ(${tunnelLength * pc}px)`
    );

    debugEl.innerHTML = `Total progress: ${pc.toFixed(2)}<br /> Step ${
      currentStep + 1
    } / ${stepCount}<br />Step progress: ${currentStepPc.toFixed(
      2
    )} <br /><br /><br />${stepsProgress.map(
      (stepPc, i) => `Step ${i + 1}: ${stepPc.toFixed(2)}<br/>`
    )}`;

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const setMinMax = (rect) => {
    const st = getScrollTop();
    min = rect.top + st;
    max = rect.bottom + st - window.innerHeight;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      setMinMax(entries[0].boundingClientRect);
      resizeObserver.observe(document.body);
      raf = requestAnimationFrame(() => pollScroll());
    } else {
      resizeObserver.unobserve(document.body);
      cancelAnimationFrame(raf);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setMinMax(el.getBoundingClientRect());
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);
  // setGrid();
  setStepOffsets();
};

export default SectionSeperator;
