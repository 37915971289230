// This file controls how Tailwind processes your CSS. For details, see
// https://tailwindcss.com/docs/configuration

module.exports = {
  //
  // WARNING: By default, CodeKit automatically populates the `content` array with all entries from [Project Settings > PurgeCSS]
  // in CodeKit's UI. If you add ANY entries to the `content` array here, CodeKit will not auto-populate the array; it becomes your
  // responsibility to include every type of file in your project that uses CSS rules. It is preferable to edit the PurgeCSS content
  // list in CodeKit's UI.
  //
  // WARNING: DO NOT delete `content` or comment it out. If you do, CodeKit will treat this as a Tailwind 2.x project instead of 3.x.
  //
  content: ["./source/**/*.{html,js}"],

  //
  // All other TailwindCSS options are 100% under your control. Edit this config file as shown in the Tailwind Docs
  // to enable the settings or customizations you need.
  //

  theme: {
    // GT Planar Break Points
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      xxl: "1536px",
    },

    // GT Planar Color Scheme
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      red: {
        DEFAULT: "#FF003D",
        md: "#590101",
        dk: "#280000",
      },
      green: {
        DEFAULT: "#00FF85",
        md: "#005C00",
        dk: "#002C00",
      },
      blue: {
        DEFAULT: "#6100FF",
        md: "#29006C",
        dk: "#1A0014",
        lt: "#7B70FF",
      },
      purple: "#7000FF",
      yellow: {
        DEFAULT: "#FCFF76",
        dk: "#282800",
      },
      magenta: "#FF00C7",
      orange: "#FF8A00",
      gray: {
        DEFAULT: "#949494",
        dk: "#606060",
      },
    },

    fontFamily: {
      sans: ["GT Planar", "Comic Sans MS", "cursive"],
    },

    fontSize: {
      // Add letter-spacing where necessary like this
      // '2xl': ['24px', {
      //   letterSpacing: '-0.01em',
      // }],
      xs: ["11px", "12px"],
      sm: ["14px", "16px"],
      base: [
        '17px',
        { letterSpacing: '0.015em',
        lineHeight: '21px' },
      ],
      rg: [
        '17px',
        { letterSpacing: '0.015em',
        lineHeight: '21px' },
      ],
      md: ["20px", "24px"],
      lg: ["24px", "28px"],
      xl: ["32px", "38px"],
      "2xl": ["4.35vw", "100%"],
      "3xl": ["5vw", "90%"],
      "4xl": ["10.15vw", "90%"],
      "5xl": ["13vw", "80%"],
      "6xl": ["16vw", "80%"],
      "7xl": ["19.5vw", "80%"],
      "8xl": ["23vw", "80%"],
    },

    spacing: {
      px: "1px",
      0: "0",
      1: "1px",
      2: "2px",
      3: "5px",
      4: "10px",
      5: "15px",
      6: "25px",
      7: "50px",
      8: "100px",
      9: "150px",
      10: "300px",
    },

    extend: {
      // For extending any existing themes, i.e. a larger breakpoint
    },
  },

  variants: {},

  //
  // If you want to run any Tailwind plugins (such as 'tailwindcss-typography'), simply install those into the Project via the
  // Packages area in CodeKit, then pass their names (and, optionally, any configuration values) here.
  // Full file paths are not necessary; CodeKit will find them.
  //
  plugins: [require("tailwindcss-crossbrowser-touch")()],
};
