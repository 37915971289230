export const randomInBounds = (min, max) => {
  return min + Math.random() * (max - min);
};

let scrollTop = 0;
document.body.addEventListener("scroll", () => {
  scrollTop = document.body.scrollTop;
});

export const getScrollTop = (scrollViewport) => {
  if (typeof window === "undefined") return 0;
  if (scrollViewport !== document) return scrollTop;

  if (typeof pageYOffset != "undefined") {
    return pageYOffset;
  } else {
    const B = document.body; //IE 'quirks'
    let D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
};

export const lerp = (x, y, t) => {
  return x * (1 - t) + y * t;
};

export const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const deviceGrade = () => {
  if ("deviceMemory" in navigator && "hardwareConcurrency" in navigator) {
    if (navigator.hardwareConcurrency >= 4 && navigator.deviceMemory >= 8) {
      return "high";
    }
    if (navigator.hardwareConcurrency >= 2 && navigator.deviceMemory >= 4) {
      return "medium";
    }
    return "low";
  }

  if ("hardwareConcurrency" in navigator) {
    if (navigator.hardwareConcurrency >= 4) {
      return "high";
    }
    if (navigator.hardwareConcurrency >= 2) {
      return "medium";
    }
    return "low";
  }

  return "unknown";
};

/*
 * Converts a value from a source range to a destination range.
 */
export const convertToRange = (value, srcRange, dstRange) => {
  if (value < srcRange[0]) return dstRange[0];
  if (value > srcRange[1]) return dstRange[1];

  const srcMax = srcRange[1] - srcRange[0];
  const dstMax = dstRange[1] - dstRange[0];
  const adjValue = value - srcRange[0];

  return (adjValue * dstMax) / srcMax + dstRange[0];
};
