import vert from "./pp.vert.glsl";
import frag from "./pp.frag.glsl";

import {
  createProgramInfo,
  createBufferInfoFromArrays,
  drawBufferInfo,
  setUniforms,
  setBuffersAndAttributes,
  createFramebufferInfo,
  bindFramebufferInfo,
} from "twgl.js";

const PASSES = 6;
const RADIUS = 250;

const PostProcessingProgram = (gl) => {
  let strength = 1;
  const programInfo = createProgramInfo(gl, [vert, frag]);
  let frameBufferInfos = [createFramebufferInfo(gl), createFramebufferInfo(gl)];

  const uniforms = {
    uResolution: [gl.canvas.width, gl.canvas.height],
    uRadius: 10,
    uStrength: 1,
  };

  const arrays = {
    position: [-1, -1, 0, 1, -1, 0, -1, 1, 0, -1, 1, 0, 1, -1, 0, 1, 1, 0],
  };

  const bufferInfo = createBufferInfoFromArrays(gl, arrays);

  const pingPongBufferInfos = ([pingBuffer, pongBuffer]) => {
    return [pongBuffer, pingBuffer];
  };

  const update = (time) => {};

  const setInputTexture = (texture) => {
    uniforms.uTexture = texture;
  };

  const setStrength = (newStrength) => {
    strength = newStrength;
    uniforms.uStrength = strength;
  };

  //
  const render = ({ renderToScreen }) => {
    const [writeFrameBufferInfo] = frameBufferInfos;
    gl.useProgram(programInfo.program);
    gl.bindVertexArray(null); // bug in twgl means we have to do this manually because instanced drawing sets the vertext array
    setBuffersAndAttributes(gl, programInfo, bufferInfo);
    setUniforms(programInfo, uniforms);
    bindFramebufferInfo(gl, writeFrameBufferInfo);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    drawBufferInfo(gl, bufferInfo, gl.TRIANGLES, bufferInfo.numElements, 0);

    for (let i = 0; i < PASSES; i++) {
      uniforms.uRadius = ((i + 1) / PASSES) * RADIUS * strength;
      frameBufferInfos = pingPongBufferInfos(frameBufferInfos);
      const [writeFrameBufferInfo, readFrameBufferInfo] = frameBufferInfos;
      setInputTexture(readFrameBufferInfo.attachments[0]);

      setBuffersAndAttributes(gl, programInfo, bufferInfo);
      setUniforms(programInfo, uniforms);
      if (renderToScreen && i === PASSES - 1) {
        bindFramebufferInfo(gl, null);
      } else {
        bindFramebufferInfo(gl, writeFrameBufferInfo);
      }
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      drawBufferInfo(gl, bufferInfo, gl.TRIANGLES);
    }
  };

  return { update, setInputTexture, setStrength, render };
};

export default PostProcessingProgram;
