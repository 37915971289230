const _temp0 = require("url:./aa_mobile.json");
const _temp1 = require("url:./aa_web.json");
const _temp2 = require("url:./ace.json");
const _temp3 = require("url:./economic_mobile.json");
const _temp4 = require("url:./economic_web.json");
const _temp5 = require("url:./et.json");
const _temp6 = require("url:./et_mobile.json");
const _temp7 = require("url:./et_web.json");
const _temp8 = require("url:./j_mobile.json");
const _temp9 = require("url:./j_web.json");
const _temp10 = require("url:./kreis_mobile.json");
const _temp11 = require("url:./kreis_web.json");
const _temp12 = require("url:./run.json");
const _temp13 = require("url:./space_mobile.json");
const _temp14 = require("url:./space_web.json");
const _temp15 = require("url:./straight_mobile.json");
const _temp16 = require("url:./straight_web.json");
module.exports = {
  "aa_mobile.json": _temp0,
  "aa_web.json": _temp1,
  "ace.json": _temp2,
  "economic_mobile.json": _temp3,
  "economic_web.json": _temp4,
  "et.json": _temp5,
  "et_mobile.json": _temp6,
  "et_web.json": _temp7,
  "j_mobile.json": _temp8,
  "j_web.json": _temp9,
  "kreis_mobile.json": _temp10,
  "kreis_web.json": _temp11,
  "run.json": _temp12,
  "space_mobile.json": _temp13,
  "space_web.json": _temp14,
  "straight_mobile.json": _temp15,
  "straight_web.json": _temp16
}