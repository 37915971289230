import { throttle } from "lodash";

const CharSet = (el) => {
  let maxX = window.innerWidth;
  let maxY = window.innerHeight;
  const zoomEl = el.querySelector("[data-component='char-set__zoom']");
  const setContainerEls = [
    ...el.querySelectorAll("[data-component='char-set__set-container']"),
  ];
  const charEls = [...el.querySelectorAll("[data-component='char-set__char']")];
  const cubeSpanEls = [
    ...el.querySelectorAll("[data-component='char-set__cube-span']"),
  ];
  const isTouch =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const onResize = throttle(() => {
    const rect = zoomEl.getBoundingClientRect();
    maxX = window.innerWidth - rect.width - 10;
    maxY = window.innerHeight - rect.height - 10;
  }, 99);

  const onScroll = () => {
    zoomEl.classList.add("invisible");
  };

  const onTouchMoveContainer = (e) => {
    const { clientX, clientY } = e.touches[0];
    const element = document.elementFromPoint(clientX, clientY);
    if (element && element.dataset.component === "char-set__char") {
      onMouseEnterChar({ target: element });
    }

    onMouseMoveContainer(e.touches[0]);
  };

  const onMouseMoveContainer = (e) => {
    const { clientX, clientY } = e;
    zoomEl.style.transform = `translate(${Math.min(
      maxX,
      clientX
    )}px, ${Math.min(maxY, clientY)}px)`;
    zoomEl.classList.remove("invisible");
  };

  const onMouseEnterContainer = () => {
    if (!isTouch) {
      document.body.addEventListener("scroll", onScroll, { passive: true });
    }
  };

  const onMouseLeaveContainer = () => {
    zoomEl.classList.add("invisible");
    document.body.removeEventListener("scroll", onScroll);
  };

  const onMouseEnterChar = (e) => {
    if (zoomEl.innerHTML === e.target.innerHTML) return;
    zoomEl.innerHTML = e.target.innerHTML;
    cubeSpanEls.forEach((el) => (el.innerHTML = e.target.innerHTML));
    const computedStyle = window.getComputedStyle(e.target);
    const fontVariationSettings = computedStyle.getPropertyValue(
      "font-variation-settings"
    );
    const fontFeatureSettings = computedStyle.getPropertyValue(
      "font-feature-settings"
    );
    zoomEl.style.fontVariationSettings = fontVariationSettings;
    zoomEl.style.fontFeatureSettings = fontFeatureSettings;

    cubeSpanEls.forEach((el) => {
      el.style.fontFeatureSettings = fontFeatureSettings;
    });
  };

  setContainerEls.forEach((el) => {
    el.addEventListener("mouseenter", onMouseEnterContainer);
    el.addEventListener("touchstart", onMouseEnterContainer);
    el.addEventListener("mousemove", onMouseMoveContainer);
    el.addEventListener("touchmove", onTouchMoveContainer);
    el.addEventListener("mouseleave", onMouseLeaveContainer);
    el.addEventListener("touchleave", onMouseLeaveContainer);
  });
  window.addEventListener("touchend", onMouseLeaveContainer);

  charEls.forEach((el) => {
    el.addEventListener("mouseenter", onMouseEnterChar);
    el.addEventListener("touchmove", onMouseEnterChar);
  });

  window.addEventListener("resize", onResize);
  onResize();
  onResize.flush();
};
export default CharSet;
