import LottiePlayer from "./LottiePlayer";

const ScrambleText = (el) => {
  const textEl = el.querySelector("[data-component='scramble-text__text']");
  const pcEl = el.querySelector("[data-component='scramble-text__pc']");
  const lottieEls = [
    ...el.querySelectorAll("[data-component='scramble-text__lottie-player']"),
  ];
  const randomCharacterDict =
    "ABCEFGHIJKLMNOPQRSTUVWXYZ1234567890()[]{}­0123456789*¦_&@©®¶§^†‡$£ƒ¥€%‰#ΔΩ+−±×÷=<>°≈≥∞≤¬≠℗∞".split(
      ""
    );

  const messageCharacters = textEl.innerHTML.split("");
  const maxPercentage = 999999;

  const stepAnimation = (pc) => {
    const currentString = [];

    const onOffPc = 0.9;
    const pcOn = pc / onOffPc;
    const limitOn = Math.floor(pcOn * messageCharacters.length);

    const pcOff = (pc - onOffPc) / (1 - onOffPc);
    const limitOffScramble = Math.floor(pcOff * 4 * messageCharacters.length);
    const limitOff = Math.floor(pcOff * messageCharacters.length);

    if (pcOn <= 1) {
      for (let i = 0; i < limitOn; i++) {
        if (i > limitOn - 3 && pcOn < 1) {
          currentString.push(
            randomCharacterDict[
              Math.floor(Math.random() * randomCharacterDict.length)
            ]
          );
        } else {
          currentString.push(messageCharacters[i]);
        }
      }
    } else {
      for (let i = 0; i < messageCharacters.length; i++) {
        if (i > limitOffScramble && pc < 1) {
          currentString.push(messageCharacters[i]);
        } else if (i > limitOff) {
          if (messageCharacters[i] === " ") {
            currentString.push(" ");
          } else {
            currentString.push(
              randomCharacterDict[
                Math.floor(Math.random() * randomCharacterDict.length)
              ]
            );
          }
        }
      }
    }

    textEl.innerHTML = currentString.join("");

    let currentPc;
    if (pcOn <= 1) {
      currentPc = Math.ceil(pcOn * maxPercentage);
    } else {
      currentPc = Math.floor((1 - pcOff) * maxPercentage);
    }
    pcEl.innerHTML = currentPc;
  };

  const onEnterFrame = ({ currentTime, totalTime }) => {
    stepAnimation(currentTime / totalTime);
  };

  lottieEls.forEach((el) => LottiePlayer(el, { onEnterFrame }));

  // to do trigger and reset when in / our of screen
};
export default ScrambleText;
