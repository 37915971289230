import textFit from "textfit";

const CONFIG = {
  alignVert: false, // if true, textFit will align vertically using css tables
  alignHoriz: false, // if true, textFit will set text-align: center
  multiLine: true, // if true, textFit will not set white-space: no-wrap
  detectMultiLine: true, // disable to turn off automatic multi-line sensing
  minFontSize: 6,
  maxFontSize: 900,
  reProcess: true, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
  widthOnly: false, // if true, textFit will fit text to element width, regardless of text height
  alignVertWithFlexbox: true, // if true, textFit will use flexbox for vertical alignment
};

const TypeTesterSample = (el) => {
  const refresh = () => {
    textFit(el, CONFIG);
    const fontSize = parseFloat(
      window
        .getComputedStyle(el.firstElementChild)
        .getPropertyValue("font-size")
    );
    const children = [...el.querySelectorAll("span")];
    children.forEach((child) => {
      child.style.fontSize = `${fontSize * 0.75}px`;
    });
  };

  el.addEventListener("input", refresh);
  refresh();

  return { refresh };
};

export default TypeTesterSample;
