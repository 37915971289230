import { debounce } from "lodash";

const HUDBattery = (el) => {
  let indicatorCount;
  let currentPc = 0;
  const innerEls = [
    ...el.querySelectorAll("[data-component='hud__battery-inner'"),
  ];
  const pcEl = el.querySelector("[data-component='hud__battery-pc']");

  const setIndicators = () => {
    indicatorCount = innerEls[0].clientHeight / 6;

    innerEls.forEach((el) => {
      el.innerHTML = "";
      for (let i = 0; i < indicatorCount; i++) {
        const levelEl = document.createElement("span");
        levelEl.className = "h-[2px] w-full block bg-green";
        el.appendChild(levelEl);
      }
    });
    setPc();
  };

  const setPc = () => {
    pcEl.innerHTML = Number(currentPc).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 0,
    });
    innerEls.forEach((el) => {
      [...el.children].forEach((child, i) => {
        if (1 - i / indicatorCount <= currentPc) {
          child.classList.add("bg-green");
          child.classList.remove("bg-gray-dk");
        } else {
          child.classList.remove("bg-green");
          child.classList.add("bg-gray-dk");
        }
      });
    });
  };

  const setupFallback = () => {
    currentPc = 1;
    let interval;
    const INTERVAL_TIME = 1111;
    setPc();

    const recharge = () => {
      currentPc += 0.08;
      setPc();
      if (currentPc >= 1) {
        currentPc = 1;
        clearInterval(interval);
        interval = setInterval(decharge, INTERVAL_TIME);
      }
    };

    const decharge = () => {
      currentPc -= 0.01;
      setPc();
      if (currentPc <= 0.1) {
        clearInterval(interval);
        interval = setInterval(recharge, INTERVAL_TIME);
      }
    };

    interval = setInterval(decharge, INTERVAL_TIME);
  };

  const getDeviceBattery = async () => {
    try {
      const battery = await navigator.getBattery();
      battery.addEventListener("levelchange", () => {
        currentPc = battery.level;
        setPc();
      });

      currentPc = battery.level;
      setPc();
    } catch (error) {
      console.warn("Unable to access battery info");
      setupFallback();
    }
  };

  window.addEventListener("resize", debounce(setIndicators, 33));

  setIndicators();
  getDeviceBattery();
};

export default HUDBattery;
