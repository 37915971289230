import { theme } from "../../../tailwind.config";
import { debounce, clamp } from "lodash";
import { getScrollTop, lerp } from "../util/lib";
import useOrientationOrMousePosition from "../util/useOrientationOrMousePosition";

const AxesOverview = (el) => {
  let orientationStuff;
  let width = window.innerWidth;
  let height = window.innerHeight;
  let currentClientX = 0;
  let currentClientY = 0;
  let hasDeviceOrientation = false;

  let min;

  const onResize = debounce(() => {
    width = window.innerWidth;
    height = window.innerHeight;
    setMinMax(el.getBoundingClientRect());
  }, 111);

  const setMinMax = (rect) => {
    const st = getScrollTop();
    min = rect.top + st;
  };

  const setTypeVariationSettings = (pcX, pcY) => {
    const slnt = lerp(-45, 45, pcX);
    const wght = lerp(100, 900, pcY);

    // --slnt-color
    const absSlnt = Math.abs(slnt);
    const slntColor =
      absSlnt <= 7
        ? theme.colors.green.DEFAULT
        : absSlnt <= 20
        ? theme.colors.yellow
        : absSlnt <= 32
        ? theme.colors.orange
        : theme.colors.red.DEFAULT;
    const wghtColor =
      wght <= 250
        ? theme.colors.green.DEFAULT
        : wght <= 500
        ? theme.colors.yellow
        : wght <= 750
        ? theme.colors.orange
        : theme.colors.red.DEFAULT;

    el.style.setProperty("--slnt", `${slnt}`);
    el.style.setProperty("--wght", `${wght}`);
    el.style.setProperty("--pcx", `${pcX * 100}%`);
    el.style.setProperty("--pcy", `${pcY * 100}%`);
    el.style.setProperty("--slnt-text", `"${Math.round(slnt)}°"`);
    el.style.setProperty("--slnt-color", slntColor);
    el.style.setProperty("--wght-text", `"${Math.round(wght)}"`);
    el.style.setProperty("--wght-color", wghtColor);
  };

  // const calculateMousePc = () => {
  //   // this is nice but only works if the section is 100vp exactly
  //   // const st = getScrollTop();
  //   // const positionInVp = min - st;

  //   const pcX = clamp(currentClientX / width, 0, 1);
  //   const pcY = clamp(currentClientY / height, 0, 1);

  //   setTypeVariationSettings(pcX, pcY);
  // };

  // const onScroll = () => {
  //   calculateMousePc();
  // };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    currentClientX = clientX;
    currentClientY = clientY;

    // calculateMousePc();
    setTypeVariationSettings(pcX, pcY);
  };

  const onFirstDeviceMotion = () => {
    hasDeviceOrientation = true;
    // window.removeEventListener("scroll", onScroll);
  };

  const onDeviceOrientation = ([gamma, beta], [pcX, pcY]) => {
    setTypeVariationSettings(pcX, pcY);
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      orientationStuff.enable();
      setMinMax(entries[0].boundingClientRect);
      if (!hasDeviceOrientation) {
        // window.addEventListener("scroll", onScroll);
      }
      window.addEventListener("resize", onResize);
      resizeObserver.observe(document.body);
    } else {
      orientationStuff.disable();
      // window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
      resizeObserver.unobserve(document.body);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setMinMax(el.getBoundingClientRect());
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);

  orientationStuff = useOrientationOrMousePosition(
    onMouseMove,
    onDeviceOrientation,
    onFirstDeviceMotion,
    el
  );
  el.addEventListener("click", orientationStuff.requestOrientationPermission);
};

export default AxesOverview;
