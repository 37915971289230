import { theme } from "../../../tailwind.config";

const FamilyOverviewTable = (el) => {
  const isTouch =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const cells = [...el.querySelectorAll("td")];
  const colours = [
    theme.colors.red.DEFAULT,
    theme.colors.orange,
    theme.colors.yellow.DEFAULT,
    theme.colors.green.DEFAULT,
    theme.colors.yellow.DEFAULT,
    theme.colors.orange,
    theme.colors.red.DEFAULT,
  ];

  const setDataAttributes = () => {
    cells.forEach((cell, i) => {
      const computedStyle = window.getComputedStyle(cell);
      const fontVariationSettings = computedStyle.getPropertyValue(
        "font-variation-settings"
      );
      cell.dataset.fontVariationSettings = fontVariationSettings;
      cell.dataset.color = colours[i % 7];
    });
  };

  const onMouseEnterCell = (e) => {
    el.classList.add("active");
    e.target.classList.add("js-family-overview-cell-hover");
    el.style.setProperty(
      "--font-variation-settings",
      e.currentTarget.dataset.fontVariationSettings
    );
    el.style.setProperty("--color", e.currentTarget.dataset.color);
  };

  const onMouseLeaveCell = (e) => {
    el.classList.remove("active");
    e.target.classList.remove("js-family-overview-cell-hover");
    el.style.setProperty("--font-variation-settings", "");
    el.style.setProperty("--color", "");
  };

  cells.forEach((cell) => {
    if (isTouch) {
      cell.addEventListener("touchstart", onMouseEnterCell);
      cell.addEventListener("touchend", onMouseLeaveCell);
    } else {
      cell.addEventListener("mouseenter", onMouseEnterCell);
      cell.addEventListener("mouseleave", onMouseLeaveCell);
    }
  });
  window.addEventListener("touchend", () => setTimeout(onMouseLeaveCell, 0));

  setDataAttributes();
};

export default FamilyOverviewTable;
