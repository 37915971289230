precision mediump float;

uniform vec2 uResolution;
uniform sampler2D uTexture;
uniform float uRadius;
uniform float uStrength;

vec2 cartesianToPolar(vec2 coords) {
  float r = sqrt((coords.x * coords.x) + (coords.y * coords.y));
  float theta = atan(coords.y, coords.x);
  return vec2(r, theta);
}

vec2 polarToCathesian(vec2 coords) {
  float r = coords.x;
  float t = coords.y;
  float x = r * cos(t);
  float y = r * sin(t);
  return vec2(x, y);
}

void main() {
  vec2 uv = gl_FragCoord.xy / uResolution;
  vec4 originalColour = texture2D(uTexture, uv) * 0.9;

  // translate UV s to center uv *= vec2(2.0);
  uv *= vec2(2.0);
  uv -= vec2(1.0);

  float resolutionRadius = sqrt(uResolution.x * uResolution.x + uResolution.y * uResolution.y) / 2.0;
  vec2 polar = cartesianToPolar(uv);

  polar.x -= uRadius * polar.x / resolutionRadius;
  polar.x = clamp(polar.x, 0.0, resolutionRadius);

  uv = polarToCathesian(polar);
  uv += vec2(1.0);
  uv /= vec2(2.0);

  vec4 sample = texture2D(uTexture, uv);
  originalColour += sample * 0.5;
  float vignetteStrength = (1.0 - smoothstep(0.0, polar.x, 0.7)) * uStrength * 0.4;
  gl_FragColor = vec4(originalColour.rgb, originalColour.a - vignetteStrength);
}