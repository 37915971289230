import { clamp } from "lodash";
import IntroBackground from "./IntroBackground";
import { getScrollTop, lerp } from "../../util/lib";

const IntroTextAnimation = (el) => {
  const planeEls = [
    ...el.querySelectorAll("[data-component='intro__plane--first']"),
  ];
  const letterEls = [
    ...el.querySelectorAll("[data-component='intro__title-letter']"),
  ];
  const letterSettings = [];
  const finalPlaneEl = planeEls[planeEls.length - 1];
  const scrollDownEl = el.querySelector(
    "[data-component='intro__scroll-down']"
  );
  const hudEl = document.querySelector('[data-component="hud"]');

  const starsBackground = IntroBackground(el);

  let raf;
  let min, max;
  let isIntroComplete;
  let isIntroStarted = false;

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    const pc = clamp((st - min) / (max - min), 0, 1);
    if (pc >= 1 && !isIntroComplete) {
      isIntroComplete = true;
      hudEl.classList.remove("scroll-animations-active");
      document.body.classList.remove("intro-active");
      const messageCenterEvent = new CustomEvent("message-centre-override", {
        detail: null,
      });
      window.dispatchEvent(messageCenterEvent);
    } else if (pc < 1 && isIntroComplete) {
      isIntroComplete = false;
      document.body.classList.add("intro-active");
    }

    if (!window.hudToggleWasClicked) {
      const hudInactiveScale = lerp(1, 1.8, pc);
      hudEl.style.setProperty(`--hud-inactive-scale`, `${hudInactiveScale}`);
    }

    letterEls.forEach((el, i) => {
      const slnt = lerp(letterSettings[i].slnt, 0, pc);
      const letterSpc = lerp(letterSettings[i].letterSpc, 0, pc);

      el.style.setProperty("--slnt", `${slnt}`);
      el.style.setProperty("--letter-spc", `${letterSpc}em`);
    });

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const setupLetters = () => {
    letterEls.forEach((el) => {
      const computedStyle = getComputedStyle(el);
      const slnt = parseFloat(computedStyle.getPropertyValue("--slnt"));
      const letterSpc = parseFloat(
        computedStyle.getPropertyValue("--letter-spc")
      );
      letterSettings.push({ slnt, letterSpc });
    });
  };

  const setMinMax = (rect) => {
    const st = getScrollTop();
    min = rect.top + st;
    max = min + rect.height * 0.2;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      starsBackground.play();
      if (!isIntroStarted) {
        isIntroStarted = true;
        setTimeout(() => {
          requestAnimationFrame(runAnimation);
        }, 0);
      }
      setMinMax(entries[0].boundingClientRect);
      resizeObserver.observe(document.body);
      raf = requestAnimationFrame(() => pollScroll());
    } else {
      starsBackground.pause();
      resizeObserver.unobserve(document.body);
      cancelAnimationFrame(raf);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setMinMax(el.getBoundingClientRect());
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  const onIntroComplete = () => {
    const messageCenterEvent = new CustomEvent("message-centre-override", {
      detail: "scroll-now",
    });
    window.dispatchEvent(messageCenterEvent);

    hudEl.classList.add("scroll-animations-active");
    document.body.classList.remove("locked");
    scrollDownEl.classList.remove("opacity-0");
    scrollDownEl.classList.remove("-translate-y-[50%]");
  };

  const runAnimation = () => {
    // force reflow.
    [...planeEls, finalPlaneEl, ...letterEls, scrollDownEl].forEach(
      (el) => el.offsetHeight
    );

    requestAnimationFrame(() => {
      el.classList.add("intro--enter");
    });

    const onFinalFrameTransitionEnd = (e) => {
      if (e.propertyName.toLowerCase().indexOf("transform") !== -1) {
        hudEl.classList.add("timewarp-active");
        starsBackground.startTimewarp(2000, () => {
          setTimeout(() => {
            starsBackground.endTimewarp(2000, () => {
              hudEl.classList.remove("timewarp-active");
            });
            el.classList.add("intro--enter-final");
            setTimeout(onIntroComplete, 1750);
          }, 1250);
        });
      }
    };

    finalPlaneEl.addEventListener("transitionend", onFinalFrameTransitionEnd);
    finalPlaneEl.addEventListener(
      "webkitTransitionEnd",
      onFinalFrameTransitionEnd
    );
    finalPlaneEl.addEventListener(
      "mozTransitionEnd",
      onFinalFrameTransitionEnd
    );
    finalPlaneEl.addEventListener("msTransitionEnd", onFinalFrameTransitionEnd);
    finalPlaneEl.addEventListener("oTransitionEnd", onFinalFrameTransitionEnd);
  };

  setupLetters();
  inViewObserver.observe(el);
};

export default IntroTextAnimation;
