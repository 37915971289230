import vert from "./stars.vert.glsl";
import frag from "./stars.frag.glsl";

import {
  m4,
  createProgramInfo,
  createBufferInfoFromArrays,
  v3,
  drawBufferInfo,
  setUniforms,
  setBuffersAndAttributes,
  createFramebufferInfo,
  createVertexArrayInfo,
  bindFramebufferInfo,
  addExtensionsToContext,
  primitives,
} from "twgl.js";
import { randomInBounds } from "../../../util/lib";

const INSTANCE_COUNT = Math.max(
  Math.min(window.innerWidth * 10, 1000 * 12),
  1000 * 8
);
const CUBE_SIZE = window.innerWidth < 768 ? 1.5 : 1;
const SPREAD = 1000 * 2;
const BOUNDS = {
  min: v3.create(-SPREAD, -SPREAD, 0),
  max: v3.create(SPREAD, SPREAD, 1000 * 10),
};

const InstancedStarsProgram = (gl) => {
  addExtensionsToContext(gl);
  if (!gl.drawArraysInstanced || !gl.createVertexArray) {
    alert("need drawArraysInstanced and createVertexArray"); // eslint-disable-line
    return;
  }
  const programInfo = createProgramInfo(gl, [vert, frag]);
  const frameBufferInfo = createFramebufferInfo(gl);

  const fov = (30 * Math.PI) / 180;
  const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  const zNear = 0.001;
  const zFar = BOUNDS.max[2] * 0.5;
  const projection = m4.perspective(fov, aspect, zNear, zFar);
  const eye = [0, 0, 0];
  const target = [0, 0, 1];
  const up = [0, 1, 0];

  let timewarp = 0;

  const camera = m4.lookAt(eye, target, up);
  const view = m4.inverse(camera);
  const viewProjection = m4.multiply(projection, view);

  const uniforms = {
    uWorldViewProjection: viewProjection,
    uTunnelLength: BOUNDS.max[2],
    uTime: 0,
    uTimeScale: 0.33,
    uPixelRatio: window.devicePixelRatio,
    uStretch: 1,
    uVisibleThreshold: 0, // everything with a seed over this threshold will be visible in timewarp
  };

  const offsets = [];
  const seeds = [];

  for (var i = 0; i < INSTANCE_COUNT; i++) {
    const x = randomInBounds(BOUNDS.min[0], BOUNDS.max[0]);
    const y = randomInBounds(BOUNDS.min[1], BOUNDS.max[1]);
    const z = randomInBounds(BOUNDS.min[2], BOUNDS.max[2]);
    seeds.push(Math.random());
    offsets.push(x, y, z);
  }

  const arrays = {
    ...primitives.createCubeVertices(CUBE_SIZE),
    offset: {
      numComponents: 3,
      data: offsets,
      divisor: 1,
    },
    seed: {
      numComponents: 1,
      data: seeds,
      divisor: 1,
    },
  };
  const bufferInfo = createBufferInfoFromArrays(gl, arrays);
  const vertexArrayInfo = createVertexArrayInfo(gl, programInfo, bufferInfo);

  const setTimewarp = (newTimewarp) => {
    timewarp = newTimewarp;
  };

  const update = (delta) => {
    uniforms.uVisibleThreshold = timewarp * 0.4;
    uniforms.uTime += delta * (1 + timewarp * 20);
    uniforms.uStretch = timewarp * 1000;
  };

  const render = ({ renderToScreen }) => {
    // return;
    gl.useProgram(programInfo.program);
    setBuffersAndAttributes(gl, programInfo, vertexArrayInfo);
    setUniforms(programInfo, uniforms);
    if (renderToScreen) {
      bindFramebufferInfo(gl, null);
    } else {
      bindFramebufferInfo(gl, frameBufferInfo);
    }
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    drawBufferInfo(
      gl,
      vertexArrayInfo,
      gl.TRIANGLES,
      vertexArrayInfo.numElements,
      0,
      INSTANCE_COUNT
    );

    return frameBufferInfo;
  };

  return { update, setTimewarp, render };
};

export default InstancedStarsProgram;
