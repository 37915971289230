import HUDBattery from "./HudBattery";
import HUDDistance from "./HudDistance";
import HUDPilot from "./HudPilot";
import HUDSpeedo from "./HudSpeedo";
import HUDTapeCounter from "./HudTapeCounter";
import HUDTilt from "./HudTilt";

const HUD = (el) => {
  const toggleEls = document.querySelectorAll("[data-component='hud__toggle']");
  toggleEls.forEach((toggleEl) =>
    toggleEl.addEventListener("click", () => {
      el.classList.remove("disable-transition");
      if (
        document.body.classList.contains("intro-active") &&
        !window.hudToggleWasClicked
      ) {
        window.hudToggleWasClicked = true;
        el.style.setProperty("--hud-inactive-scale", "1.8");
        return;
      }
      document.body.classList.toggle("hud-overlay-active");
      const toggleEvent = new CustomEvent("hud-toggle", {
        detail: document.body.classList.contains("hud-overlay-active"),
      });
      window.dispatchEvent(toggleEvent);
    })
  );

  window.addEventListener("tt-toggle", ({ detail }) => {
    if (detail) {
      document.body.classList.remove("hud-overlay-active");
    }
  });
  HUDBattery(el.querySelector("[data-component='hud__battery']"));
  HUDSpeedo(el.querySelector("[data-component='hud__speedo']"));
  HUDDistance(el.querySelector("[data-component='hud__distance']"));
  HUDTapeCounter(el.querySelector("[data-component='hud__tape-counter']"));
  HUDTilt(el.querySelector("[data-component='hud__tilt']"));
  HUDPilot(el.querySelector("[data-component='hud__pilot']"));
};

export default HUD;
