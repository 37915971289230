import * as astroSrcs from "url:../../../images/hud/astronaut/*";
console.log(astroSrcs);

const HUDPilot = (el) => {
  const keys = Object.keys(astroSrcs);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  el.style.backgroundImage = `url(${astroSrcs[randomKey]})`;
};

export default HUDPilot;
