import LottiePlayer from "./components/LottiePlayer";
import HUD from "./components/HUD/Hud";
import IntroTextAnimation from "./components/Intro/IntroTextAnimation";
import AxesOverview from "./components/AxesOverview";
import FamilyStyleOverview from "./components/FamilyStyleOverview";
import ScrambleText from "./components/ScrambleText";
import SectionSeperator from "./components/SectionSeperator";
import FamilyOverviewTable from "./components/FamilyOverviewTable";
import MessageCentre from "./components/MessageCentre";
import TypeTester from "./components/TypeTester/TypeTester";
import CharSet from "./components/CharSet";
import { debounce } from "lodash";

const onResize = debounce(() => {
  requestAnimationFrame(() => {
    document.documentElement.style.setProperty(
      "--visible-viewport-height",
      `${window.innerHeight}px`
    );
  });
}, 111);

const kickIt = () => {
  onResize();
  onResize.flush();
  window.addEventListener("resize", onResize);

  const intro = document.querySelector("[data-component='intro']");
  if (intro) {
    IntroTextAnimation(intro);
  }

  const hudEl = document.querySelector("[data-component='hud']");
  if (hudEl) {
    HUD(hudEl);
  }

  const axesOverviewEl = document.querySelector(
    "[data-component='axes-overview']"
  );
  if (axesOverviewEl) {
    AxesOverview(axesOverviewEl);
  }

  const familyStyleOverviewEl = document.querySelector(
    "[data-component='family-style-overview']"
  );
  if (familyStyleOverviewEl) {
    FamilyStyleOverview(familyStyleOverviewEl);
  }

  const scrambleTextEl = document.querySelector(
    "[data-component='scramble-text']"
  );
  if (scrambleTextEl) {
    ScrambleText(scrambleTextEl);
  }

  const sectionSeperators = [
    ...document.querySelectorAll("[data-component='ss']"),
  ];
  sectionSeperators.forEach((el) => SectionSeperator(el));

  const lottieEls = [
    ...document.querySelectorAll("[data-component='lottie-player']"),
  ];
  lottieEls.forEach((el) => LottiePlayer(el));

  const familyOverviewTableEl = document.querySelector(
    "[data-component='family-overview-table']"
  );
  if (familyOverviewTableEl) {
    FamilyOverviewTable(familyOverviewTableEl);
  }

  const charSetEl = document.querySelector("[data-component='char-set']");
  if (charSetEl) {
    CharSet(charSetEl);
  }

  const messageCentreEls = [
    ...document.querySelectorAll("[data-component='message-centre']"),
  ];
  messageCentreEls.forEach((el) => MessageCentre(el));

  const TypeTesterEl = document.querySelector("[data-component='tt']");
  if (TypeTesterEl) {
    TypeTester(TypeTesterEl);
  }

  const requestOrientationPermission = async () => {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      // iOS 13+
      try {
        await DeviceOrientationEvent.requestPermission();
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  };

  const requestMotionButtons = [
    ...document.querySelectorAll("[data-component='request-motion']"),
  ];

  requestMotionButtons.forEach((button) =>
    button.addEventListener("click", requestOrientationPermission)
  );
};

if (document.addEventListener) {
  document.addEventListener("DOMContentLoaded", () =>
    requestAnimationFrame(kickIt)
  );
} else {
  window.attachEvent("onload", () => requestAnimationFrame(kickIt));
}
