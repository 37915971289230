import { debounce } from "lodash";
import { lerp } from "../../util/lib.js";
import useOrientationOrMousePosition from "../../util/useOrientationOrMousePosition.js";

const HUDTilt = (el) => {
  const puckTracks = [
    ...el.querySelectorAll("[data-component='hud__vec-puck-track']"),
  ];
  const puckTrackInners = [
    ...el.querySelectorAll("[data-component='hud__vec-puck-track-inner']"),
  ];
  const puckEls = [...el.querySelectorAll("[data-component='hud__vec-puck']")];
  const vecPxEl = el.querySelector("[data-component='hud__vec-px']");
  const tiltPcEl = el.querySelector("[data-component='hud__tilt-pc']");
  const tiltLevel = el.querySelector("[data-component='hud__tilt-level']");

  let trackHeights = [puckTracks[0].clientHeight, puckTracks[1].clientHeight];
  let puckHeights = [puckEls[0].clientHeight, puckEls[1].clientHeight];

  const onResize = () => {
    trackHeights = [puckTracks[0].clientHeight, puckTracks[1].clientHeight];
    puckHeights = [puckEls[0].clientHeight, puckEls[1].clientHeight];
  };

  const setValues = (pxY, pcX, pcY) => {
    const tiltRotate = lerp(-45, 45, pcX);
    const tiltText =
      Math.round(tiltRotate) >= 0
        ? `+${Math.round(tiltRotate)}°`
        : `${Math.round(tiltRotate)}°`;

    const vecTranslateOuter = [
      `translateY(${pcY * (trackHeights[0] - puckHeights[0] - 2)}px)`, // minus 2 for padding and border;
      `translateY(${pcY * (trackHeights[1] - puckHeights[1] - 14)}px)`, // minus 14 for padding and border;
    ];

    puckTrackInners.forEach((el) => {
      el.style.height = `${pcY * 100}%`;
    });

    const tiltTransform = `rotate(${tiltRotate},47,44.5)`;
    tiltPcEl.innerHTML = tiltText;
    puckEls[0].style.transform = vecTranslateOuter[0];
    puckEls[1].style.transform = vecTranslateOuter[1];
    if (pxY !== null) {
      vecPxEl.innerHTML = String(pxY).padStart(4, "0");
    }
    tiltLevel.setAttribute("transform", tiltTransform);
  };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    setValues(clientY, pcX, pcY);
  };
  const onDeviceMotion = (value, [pcX, pcY]) => {
    setValues(null, pcX, pcY);
  };
  const onFirstDeviceMotion = () => {};
  window.addEventListener("resize", debounce(onResize, 66));

  const { enable, disable, requestOrientationPermission } =
    useOrientationOrMousePosition(
      onMouseMove,
      onDeviceMotion,
      onFirstDeviceMotion
    );

  enable();
};

export default HUDTilt;
