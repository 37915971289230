import { debounce } from "lodash";
import { getScrollTop, lerp } from "../../util/lib.js";

const HUDSpeedo = (el) => {
  const svgForeignObject = el.querySelector(
    "[data-component='hud__speedo-fobj']"
  );
  const dialLine = el.querySelector("[data-component='hud__speedo-dial']");

  let timeout;
  let isPolling = false;
  let lastScrollTop = getScrollTop();
  let then;
  const POLL_TO = 66;

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    const delta = Math.abs(lastScrollTop - st);
    lastScrollTop = st;

    const now = performance.now();
    const timeDelta = now - then;
    then = now;

    const speedPerMs = delta / timeDelta;
    const speedPerS = Math.round(speedPerMs * 1000);
    const rotation = lerp(-45, 45, Math.min(speedPerS / 2222, 1));

    requestAnimationFrame(() => {
      svgForeignObject.innerHTML = speedPerS;
      svgForeignObject.dataset.outlineDuplicateText = speedPerS;
      dialLine.style.setProperty("--rotation", `${rotation}deg`);
    });

    if (loop) {
      timeout = setTimeout(pollScroll, POLL_TO);
    }
  };

  const stopPollScroll = debounce(() => {
    isPolling = false;
    clearTimeout(timeout);
  }, 1111);

  const onScroll = () => {
    if (!isPolling) {
      isPolling = true;
      then = performance.now();
      timeout = setTimeout(pollScroll, POLL_TO);
    }
    stopPollScroll();
  };

  document.body.addEventListener("scroll", onScroll, { passive: true });
};

export default HUDSpeedo;
