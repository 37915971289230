precision mediump float;
uniform mat4 uWorldViewProjection;
uniform float uTime;
uniform float uTimeScale;
uniform float uTunnelLength;
uniform float uStretch;
uniform float uVisibleThreshold;

attribute vec3 offset;
attribute float seed;
attribute vec4 position;

varying float vProgress;
varying float vOpacity;

void main() {
  vOpacity = step(uVisibleThreshold, seed);
  vOpacity = smoothstep(seed, 1.0, 1.0 - uVisibleThreshold);

  vec3 animatedOffset = offset * vec3(1.0, 1.0, 1000.0);
  animatedOffset.z -= uTime * uTimeScale;
  animatedOffset.z = fract(animatedOffset.z / uTunnelLength) * uTunnelLength - uStretch * 0.5;

  vProgress = 1.0 - animatedOffset.z / uTunnelLength;
  animatedOffset = animatedOffset + position.xyz;

  // gl_Position = uWorldViewProjection * vec4(animatedOffset.xyz, 1.0);

  vec4 stretchedPosition = vec4(position);
  stretchedPosition.z += stretchedPosition.z * uStretch * vProgress, 1.0;

  vec4 offsetPosition = stretchedPosition + vec4(animatedOffset, 0.0);

  gl_Position = uWorldViewProjection * offsetPosition;
}