import { resizeCanvasToDisplaySize } from "twgl.js";
import { clamp } from "lodash";
import Easing from "easing-functions";

import InstancedStarsProgram from "./InstancedStarsProgram/InstancedStarsProgram";
import PostProcessingProgram from "./PostProcessingProgram/PostProcessingProgram";
import { lerp, deviceType, deviceGrade } from "../../util/lib";

const IntroBackground = (el) => {
  let then, now;
  let time = 0;
  let raf;
  let timewarpAnimationDuration;
  let timewarpAnimationStart;
  let currentTimewarp = 0;
  let targetTimewarp = 0;
  let timewarpCallback;

  const doPostProcessing =
    deviceType() === "desktop" && deviceGrade() !== "low";

  const canvas = el.querySelector("[data-component='intro__canvas']");
  const options = {
    antialias: false,
    alpha: doPostProcessing,
    powerPreference: "high-performance",
    percision: "mediump",
    premultipliedAlpha: false,
    depth: false,
  };
  const gl =
    typeof WebGL2RenderingContext !== "undefined"
      ? canvas.getContext("webgl2", options)
      : canvas.getContext("webgl", options);
  resizeCanvasToDisplaySize(canvas, window.devicePixelRatio);
  gl.viewport(0, 0, canvas.width, canvas.height);
  gl.clearColor(0, 0, 0, 0);

  const instancedStarsProgram = InstancedStarsProgram(gl);
  const postProcessingProgram = PostProcessingProgram(gl);

  const update = (delta) => {
    time += delta;

    if (timewarpAnimationStart) {
      const timewarpAnimationDelta = performance.now() - timewarpAnimationStart;
      let pc = clamp(timewarpAnimationDelta / timewarpAnimationDuration, 0, 1);
      pc = Easing.Cubic.InOut(pc);

      if (targetTimewarp === 0) {
        currentTimewarp = lerp(1, 0, pc);
      } else {
        currentTimewarp = lerp(0, 1, pc);
      }

      if (pc >= 1) {
        timewarpAnimationStart = null;
        if (timewarpCallback) {
          timewarpCallback();
          timewarpCallback = null;
        }
      }
    }

    // const timewarp = (Math.cos(time * 0.001) + 1.0) / 2;
    instancedStarsProgram.setTimewarp(currentTimewarp);
    postProcessingProgram.setStrength(currentTimewarp);
    instancedStarsProgram.update(delta);
  };

  const render = () => {
    if (doPostProcessing) {
      const frameBufferInfo = instancedStarsProgram.render({
        renderToScreen: false,
      });
      postProcessingProgram.setInputTexture(frameBufferInfo.attachments[0]);
      postProcessingProgram.render({
        renderToScreen: true,
      });
    } else {
      instancedStarsProgram.render({
        renderToScreen: true,
      });
    }
  };

  const animate = () => {
    now = performance.now();
    const delta = now - then;
    then = now;

    update(delta);
    render();
    raf = requestAnimationFrame(animate);
  };

  const play = () => {
    now = null;
    then = performance.now();
    cancelAnimationFrame(raf);
    raf = requestAnimationFrame(animate);
  };

  const pause = () => {
    cancelAnimationFrame(raf);
  };

  const startTimewarp = (duration, cb = null) => {
    document.body.classList.remove("intro-pre-warp");
    timewarpAnimationDuration = duration;
    timewarpCallback = cb;
    timewarpAnimationStart = performance.now();
    targetTimewarp = 1;
  };

  const endTimewarp = (duration, cb = null) => {
    timewarpAnimationDuration = duration;
    timewarpCallback = cb;
    timewarpAnimationStart = performance.now();
    targetTimewarp = 0;
  };

  return { play, pause, startTimewarp, endTimewarp };
};

export default IntroBackground;
