import { theme } from "../../../tailwind.config";
import { lerp } from "../util/lib";
import { debounce } from "lodash";
import useOrientationOrMousePosition from "../util/useOrientationOrMousePosition";
import { theme } from "../../../tailwind.config";

const FamilyStyleOverview = (el) => {
  let mode =
    window.innerWidth >= parseInt(theme.screens.lg)
      ? "bidirectional"
      : "omnidirectional";
  const indicatorWrapperEl = el.querySelector(
    "[data-component='family-style-overview__indicator']"
  );
  const rowEls = [
    ...el.querySelectorAll("[data-component='family-style-overview__row']"),
  ];
  let orientationStuff;
  let indicatorCount = 0;
  let indicators = [];
  let indicatorsLeft = [];
  let indicatorsRight = [];

  const setupIndicators = () => {
    indicatorsLeft = [];
    indicatorsRight = [];
    indicatorCount = indicatorWrapperEl.clientWidth / 6;
    indicatorCount = 2 * Math.round(indicatorCount / 2);

    indicatorWrapperEl.innerHTML = "";

    for (let i = 0; i < indicatorCount; i++) {
      const el = document.createElement("span");
      indicators.push(el);
      if (i < indicatorCount / 2) {
        indicatorsLeft.push(el);
      } else {
        indicatorsRight.push(el);
      }
      indicatorWrapperEl.appendChild(el);
    }

    indicatorsLeft.reverse();
  };

  const onResize = debounce(() => {
    mode =
      window.innerWidth >= parseInt(theme.screens.lg)
        ? "bidirectional"
        : "omnidirectional";
    setupIndicators();
  }, 111);

  const getColour = (slnt) => {
    if (Math.abs(slnt) < 12) {
      return theme.colors.green.DEFAULT;
    }

    if (Math.abs(slnt) < 24) {
      return theme.colors.yellow.DEFAULT;
    }

    if (Math.abs(slnt) < 36) {
      return theme.colors.orange;
    }

    return theme.colors.red.DEFAULT;
  };

  const setTypeVariationSettings = (pcX) => {
    if (mode === "bidirectional") {
      const pcFromCenter = Math.abs(0.5 - pcX) * 2;
      const slnt = lerp(0, 45, pcFromCenter);
      rowEls.forEach((el) => {
        el.style.setProperty("--color", getColour(slnt));
        el.style.setProperty("--slnt", `${slnt}`);
        el.style.setProperty("--minus-slnt", `${slnt * -1}`);
      });

      const indicatorThreshold = indicatorsLeft.length * pcFromCenter;

      for (let i = 0; i < indicatorCount / 2; i++) {
        if (i < indicatorThreshold) {
          indicatorsLeft[i].style.background = getColour(slnt);
          indicatorsRight[i].style.background = getColour(slnt);
        } else {
          indicatorsLeft[i].style.background = theme.colors.gray.DEFAULT;
          indicatorsRight[i].style.background = theme.colors.gray.DEFAULT;
        }
      }
    } else {
      const slnt = lerp(-45, 45, pcX);
      rowEls.forEach((el) => {
        el.style.setProperty("--color", getColour(slnt));
        el.style.setProperty("--slnt", `${slnt}`);
        el.style.setProperty("--minus-slnt", `${slnt}`);
      });

      const indicatorThreshold = indicators.length * pcX;

      for (let i = 0; i < indicators.length; i++) {
        if (i < indicatorThreshold) {
          indicators[i].style.background = getColour(slnt);
        } else {
          indicators[i].style.background = theme.colors.gray.DEFAULT;
        }
      }
    }
  };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    setTypeVariationSettings(pcX);
  };

  const onDeviceOrientation = ([gamma, beta], [pcX, pcY]) => {
    setTypeVariationSettings(pcX);
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      orientationStuff.enable();
      window.addEventListener("resize", onResize);
    } else {
      orientationStuff.disable();
      window.removeEventListener("resize", onResize);
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);

  orientationStuff = useOrientationOrMousePosition(
    onMouseMove,
    onDeviceOrientation,
    null,
    el
  );
  el.addEventListener("click", orientationStuff.requestOrientationPermission);

  setupIndicators();
};

export default FamilyStyleOverview;
