const _temp0 = require("url:./astronaut-dominik.gif");
const _temp1 = require("url:./astronaut-jonas.gif");
const _temp2 = require("url:./astronaut-katja.gif");
const _temp3 = require("url:./astronaut-kelly.gif");
const _temp4 = require("url:./astronaut-noel.gif");
const _temp5 = require("url:./astronaut-reto.gif");
const _temp6 = require("url:./astronaut-thierry.gif");
const _temp7 = require("url:./astronaut-tobias.gif");
module.exports = {
  "astronaut-dominik.gif": _temp0,
  "astronaut-jonas.gif": _temp1,
  "astronaut-katja.gif": _temp2,
  "astronaut-kelly.gif": _temp3,
  "astronaut-noel.gif": _temp4,
  "astronaut-reto.gif": _temp5,
  "astronaut-thierry.gif": _temp6,
  "astronaut-tobias.gif": _temp7
}